import App from 'next/app';
import { getSession, Provider } from 'next-auth/client';
import type { AppProps, AppContext } from 'next/app';
import '../styles/globals.css';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Provider session={pageProps.session}>
      <Component {...pageProps} />
    </Provider>
  );
}

export default MyApp;

export async function getInitialProps(appContext: AppContext) {
  const appProps = await App.getInitialProps(appContext);
  const session = await getSession();

  return {
    props: {
      session,
      ...appProps,
    },
  };
}
